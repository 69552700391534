import "../stylesheets/app.scss";

document.addEventListener("DOMContentLoaded", () => {
  const template = document.querySelector("body");
  const homepage = template.classList.contains("Homepage");
  const danceCompany = template.classList.contains("DanceCompany");
  const danceStore = template.classList.contains("DanceStorePage");
  const eventPage = template.classList.contains("Event");
  const promotionPage = template.classList.contains("Promotion");
  const spaceRentalPage = template.classList.contains("RentalPage");
  const servicesPage = template.classList.contains("ServicesPage");
  const registrationPage = template.classList.contains("RegistrationPage");
  const schedulePage = template.classList.contains("Schedule");
  const depositPage = template.classList.contains("DepositPage");
  const galleryPage = template.classList.contains("GalleryPage");
  const accountPage = template.classList.contains("AccountPage");
  const membershipPage = template.classList.contains("MembershipPage");
  const teamMembershipPage = template.classList.contains("TeamMembershipPage");

  require("./global/mobile-navigation");
  require("./global/cart");

  if (homepage) {
    require("./global/email-form-script");
    require("./homepage");
  }

  if (danceCompany || eventPage || danceStore || promotionPage || galleryPage) {
    require("./global/lightgallery");
  }

  if (galleryPage) {
    require("./gallery");
  }

  if (danceStore) {
    require("./dancestore");
  }

  if (danceCompany) {
    require("./global/lightgallery");
    require("./global/email-form-script");
  }

  if (spaceRentalPage) {
    require("./global/lightgallery");
    require("./global/email-form-script");
  }

  if (servicesPage) {
    require("./global/email-form-script");
  }

  if (registrationPage) {
    require("./registration");
  }

  if (membershipPage) {
    require("./membership");
  }

  if (teamMembershipPage) {
    require("./teammembership");
  }

  if (schedulePage) {
    require("./schedule");
  }

  if (promotionPage || eventPage) {
    require("./promotion");
  }

  if (depositPage) {
    require("./deposit");
  }

  if (accountPage) {
    require("./account");
  }
});
